var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"overflow-x-auto"},[_c('v-model-table',{attrs:{"data-function":_vm.getOrderGuestCustomerSurveysReport,"columns":_vm.columns,"show-toolbar":false},scopedSlots:_vm._u([{key:"column_order_id",fn:function(ref){
var row = ref.row;
return [_c('v-link',{attrs:{"to":{ name: 'orders.show', params: { order: row.order_id } }}},[_vm._v(" "+_vm._s(row.order_id)+" ")])]}},{key:"column_order_line_id",fn:function(ref){
var row = ref.row;
return [_c('v-link',{attrs:{"to":{ name: 'jobs.show', params: { job: row.order_line_id } }}},[_vm._v(" "+_vm._s(row.order_line_id)+" ")])]}},{key:"column_user_name",fn:function(ref){
var row = ref.row;
return [_c('v-link',{attrs:{"to":{ name: 'users.show', params: { user: row.user_id } }}},[_vm._v(" "+_vm._s(row.user_name)+" ")]),_c('v-text',{staticClass:"text-gray-500"},[_vm._v(" "+_vm._s(row.organization_name)+" ")])]}},{key:"column_game_name",fn:function(ref){
var row = ref.row;
return [_c('v-text',{staticClass:"whitespace-normal"},[_vm._v(" "+_vm._s(row.product_name.trim().toLowerCase().endsWith(row.game_name.trim().toLowerCase().replaceAll(':', '')) ? row.product_name.slice(0, row.product_name.toLowerCase().indexOf(' - ' + row.game_name.toLowerCase().replaceAll(':', ''))) : row.product_name)+" ")]),_c('div',{staticClass:"w-full flex justify-start content-center space-x-2"},[_c('v-text',{staticClass:"text-gray-500"},[_vm._v(" "+_vm._s(row.game_name)+" ")]),_vm._l((row.game_platforms),function(platform,index){return [_c('div',{key:'platform_' + index,staticClass:"flex items-center space-x-1"},[_c('v-icon',{staticClass:"text-gray-500 fill-current",attrs:{"name":platform.slug,"alt":platform.name}}),_c('span',[_vm._v(" "+_vm._s(platform.name))])],1)]})],2)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }