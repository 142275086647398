<template>
    <div class="overflow-x-auto">
        <v-model-table :data-function="getOrderGuestCustomerSurveysReport" :columns="columns" :show-toolbar="false">
            <template v-slot:column_order_id="{ row }">
                <v-link :to="{ name: 'orders.show', params: { order: row.order_id } }">
                    {{ row.order_id }}
                </v-link>
            </template>
            <template v-slot:column_order_line_id="{ row }">
                <v-link :to="{ name: 'jobs.show', params: { job: row.order_line_id } }">
                    {{ row.order_line_id }}
                </v-link>
            </template>
            <template v-slot:column_user_name="{ row }">
                <v-link :to="{ name: 'users.show', params: { user: row.user_id } }">
                    {{ row.user_name }}
                </v-link>
                <v-text class="text-gray-500">
                    {{ row.organization_name }}
                </v-text>
            </template>
            <template v-slot:column_game_name="{ row }">
                <v-text class="whitespace-normal">
                    {{ row.product_name.trim().toLowerCase().endsWith(row.game_name.trim().toLowerCase().replaceAll(':', '')) ? row.product_name.slice(0, row.product_name.toLowerCase().indexOf(' - ' + row.game_name.toLowerCase().replaceAll(':', ''))) : row.product_name }}
                </v-text>

                <div class="w-full flex justify-start content-center space-x-2">
                    <v-text class="text-gray-500">
                        {{ row.game_name }}
                    </v-text>
                    <template v-for="(platform, index) in row.game_platforms">
                        <div :key="'platform_' + index" class="flex items-center space-x-1">
                            <v-icon class="text-gray-500 fill-current" :name="platform.slug" :alt="platform.name" />
                            <span> {{ platform.name }}</span>
                        </div>
                    </template>
                </div>
            </template>
        </v-model-table>
    </div>
</template>

<script>
import ReportService from "@/services/modules/report-service";

export default {
    data() {
        return {
            getOrderGuestCustomerSurveysReport: (payload = {}) => ReportService.getOrderGuestCustomerSurveysReport(payload),
            columns: {
                order_id: {
                    label: "Order",
                    fullWidth: false,
                },
                order_line_id: {
                    label: "Job",
                    fullWidth: false,
                },
                user_name: {
                    label: "Customer",
                    fullWidth: false,
                },
                game_name: {
                    label: "Product",
                    fullWidth: false,
                },
                how_did_you_hear_about_us: {
                    label: "How did you hear about us?",
                    fullWidth: false,
                },
            },
        };
    },
};
</script>
